import $ from 'jquery'
import 'core-js/es/number'
import Swiper, { Navigation, A11y, Autoplay, Pagination } from 'swiper/swiper.esm'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, A11y, Autoplay, Pagination])

class BlogSlider extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
    if($(window).width() < 1024){
      this.fix_mobile_height();
    }
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$slider = $('[data-slider]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
    this.$pagination = $('[data-slider-pagination]', this)
    this.$slide_items = $("[data-slider]").find(".slider-item", this)
  }

  // Fix Header Slider VH, adjust to inner VP because mobile browser toolbars break CSS VH
  fix_mobile_height(){
    let vh = window.innerHeight - 80;
    let slide_element = this.$slide_items;
    slide_element.each(function(){
      jQuery(this).height(vh);
    });

  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const { options } = this.props
    const config = {
      a11y: options.a11y,
      centeredSlides: true,
      loop: true,
      spaceBetween: 0,
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      },
      navigation: {
        nextEl: this.$buttonNext.get(0),
        prevEl: this.$buttonPrev.get(0)
      },
      pagination: {
        el: this.$pagination.get(0),
        clickable: true
      },
      breakpoints: {
        769: {
          pagination: {
            el: this.$pagination.get(1),
            clickable: true
          },
          navigation: {
            nextEl: this.$buttonNext.get(1),
            prevEl: this.$buttonPrev.get(1)
          },
        },
      }
      
    }

    if (options.autoplay && options.autoplaySpeed) {
      config.autoplay = {
        delay: options.autoplaySpeed
      }
    }

    this.swiper = new Swiper(this.$slider.get(0), config)
  }
}

window.customElements.define('flynt-blog-slider', BlogSlider, { extends: 'div' })

function equalize_blog_slides(){
  $(".figure-blog_slider").each(function(){
    let img = $(this);
    let container = img.parent().parent();
    let text_height = container.find(".box").outerHeight();
    img.height(text_height);
  })
}
$(document).ready(function(){
  equalize_blog_slides();
})

$(window).on("resize", function(){
  if($(window).width() >= 1024){
    equalize_blog_slides();
  }
})

