import $ from 'jquery'
import 'core-js/es/number'
import Swiper, { Navigation,  Autoplay, Pagination } from 'swiper/swiper.esm'
import 'swiper/swiper-bundle.css'

function calculate_percent(first_value, second_value){
  let result = first_value / second_value;
  result = result  * 100;
  return result;
}

function calculate_percent_of_number(percentage, value){
  percentage = percentage / 100;
  let result = value * percentage;
  return result;
}

$(function(){
  if($(".front-nav").length){
    var nav_target_offset         = 200;                                            //Ziel offset, wo nav Animation fertig sein soll
    var nav_base_padding          = 76;                                             // Basis Padding der Navigation
    var nav_end_padding           = 31;                                              //Padding der Nav am Ende der Animation
    var nav_diff                  = nav_base_padding - nav_end_padding;

    $(window).on("scroll", function(){
      let scrollPos = jQuery(window).scrollTop();//User Scroll Position
      let percent = calculate_percent(scrollPos, nav_target_offset);//percentage from user scroll pos to target offset where animation is done

      if(percent <= 100){
        let nav_padding           = nav_base_padding - calculate_percent_of_number(percent, nav_diff);
        jQuery(".front-nav").css("padding-bottom", nav_padding);
        jQuery(".front-nav").css("padding-top", nav_padding);
      }
      else{
        jQuery(".front-nav").css("padding-bottom", nav_end_padding);
        jQuery(".front-nav").css("padding-top", nav_end_padding);
      }
    
    });
  }

  $("#main-menu-cross").on("click", function(){
    $(".flynt-navigation-main-menu").fadeOut("fast");
  });

  $("#main-menu-burger").on("click", function(){
    $(".flynt-navigation-main-menu").fadeIn("fast");
  });
});

class AccordionNav extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
  }

  bindFunctions () {
    this.togglePanel = this.togglePanel.bind(this)
  }

  bindEvents () {
    this.$.on("mouseenter", '.menu-item', this.togglePanel)
    this.$.on("mouseleave", '.menu-item', this.togglePanel)
  }

  togglePanel (e) {
    const parent = $(e.currentTarget);
    const $panel = parent.find(".menu-item-link");

    if ($panel.attr('aria-expanded') === 'true') {
      $panel.attr('aria-expanded', 'false')
      $panel.next().attr('aria-hidden', 'true').slideUp(250)
    } else {
      $panel.attr('aria-expanded', 'true')
      $panel.next().attr('aria-hidden', 'false').slideDown(300)
    }
  }
}

window.customElements.define('flynt-navigation-main-menu', AccordionNav, { extends: 'nav' })

Swiper.use([Navigation, Autoplay, Pagination])

class SubtreeSlider extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
  }

  resolveElements () {
    this.$slider = $('[data-slider]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
    this.$pagination = $('[data-slider-pagination]', this)
    this.$slide_items = $("[data-slider]").find(".slider-item", this)
    this.$slide_items_count = this.$slide_items.length;
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    set_slider_width(this.$slider);
    const config = {
      loop: false,
      freeMode: true,
      direction: 'horizontal',
      spaceBetween: 10,
      slidesPerView: "auto",
      navigation: {
        nextEl: this.$buttonNext.get(0),
        prevEl: this.$buttonPrev.get(0)
      },
      on:{
        // Show / Hide linear gradient if start or end reached
        transitionEnd: function(){
          let slide_container = this.el;
          let isBeginning = this.isBeginning;
          let isEnd       = this.isEnd;
          
          // If Slider is at beginning, hide left linear gradient
          if(isBeginning == true){
            slide_container.classList.remove("fade-start");
          }
          else{
            slide_container.classList.add("fade-start");
          }

          // If Slider is at end, hide right linear gradient
          if(isEnd == true){
            slide_container.classList.remove("fade-end");
          }
          else{
            slide_container.classList.add("fade-end");
          }
        },
        resize: function(){
          let slide_container = $(this.el)
          set_slider_width(slide_container);
        }
      }
      
    }

    this.swiper = new Swiper(this.$slider.get(0), config)
  }
}

window.customElements.define('flynt-nav-subtree', SubtreeSlider, { extends: 'div' })

// Force Slider fitting width
function set_slider_width(slider_container){
  let slider_width  = 0;
  let slide_element = slider_container.find(".slider-item");
  let window_width = $(window).width();
  let slider_max_length;// Number of visible slides depending on Viewport

  if(window_width > 1400){
    slider_max_length = 4;
  }
  else if(window_width < 1400 && window_width > 1200){
    slider_max_length = 3;
  }
  else{
    slider_max_length = 2;
  }

  // Get the width from the first 4 elements
  for(var i = 0; i < slider_max_length; i++){
    let slide_element_width = slide_element.eq(i).outerWidth();
    // Only take half width from the 4th element
    if(i == slider_max_length - 1){
      slide_element_width = slide_element_width / 2;
    }
    // Add Slider Width
    slider_width += slide_element_width;
  }

  // Set Slider Width
  slider_container.width(slider_width + 100);
}
