import $ from 'jquery'

function sizeBubbles(){
  $(".bubbles-item").each(function(){
    let circle_width = $(this).width();
    let circle_height = $(this).height();
    let text_height = $(this).find(".bubbles-item-back-text").height();
    let height_diff = circle_height - text_height;

    if(height_diff < 60){
      let additional_width = 0;
      
      if(height_diff < 0){
        height_diff = 60 + Math.abs(height_diff);
      }
      else if(height_diff > 0){
        let diff    = 60 - height_diff;
        height_diff = 60 + diff;
        height_diff = 60;
      }

      additional_width = additional_width + height_diff;
      console.log(additional_width);
      $(this).css("flex-basis", circle_width + additional_width + "px");
    }
  })
}
sizeBubbles();

$(window).on("resize", function(){
  sizeBubbles();
})