import $ from 'jquery'
import Swiper, { Navigation, Autoplay } from 'swiper/swiper.esm'

/*
$(document).ready(function(){
  $(".tabs-item").on("click", function(){
    //change_tab_item($(this));
  })
})
*/
function change_tab_item(el){
  if(el.hasClass("swiper-slide-active") === false){
    console.log(el);
    let target_index = el.attr("data-target");
    
    let current_selected_tab_content = el.parent().parent().next().find(".tabs-content-item-active");
    let target_element_tab_content = el.parent().parent().next().find(".tabs-content-item[data-index='"  + target_index +"']");
  
    if(target_element_tab_content !== null && typeof target_element_tab_content !== "undefined" && target_element_tab_content.hasClass("tabs-content-item-active") === false){
      current_selected_tab_content.removeClass("tabs-content-item-active");
      target_element_tab_content.addClass("tabs-content-item-active");
      current_selected_tab_content.fadeOut("fast");
      target_element_tab_content.fadeIn("fast");
    }
  }
}


Swiper.use([Navigation, Autoplay])

class TabsSlider extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    return data
  }

  resolveElements () {
    this.$slider = $('.slider', this)
    this.$buttonNext = $('.slider-button--next', this)
    this.$buttonPrev = $('.slider-button--prev', this)
    this.$button = $(".tabs-button", this)
    this.$slide_items = $(".slider").find(".slider-item", this)
    this.$showArrows = this.$slider.attr("data-showarrows")
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const { options } = this.props
    let desktop_breakpoint_config = {};
    if(this.$showArrows === "true"){
      desktop_breakpoint_config = {
        slidesPerView: 4,
      }
    }
    else{
      desktop_breakpoint_config = {
        slidesPerView: "auto",
        loop: false,
      }
    }
    const config = {
      loop: true,
      spaceBetween: 5,
      slidesPerView: 1,
      initialSlide: 0,
      centeredSlides: true,
      loopedSlides: 50,
      slideToClickedSlide: true,
      virtualTranslate: false,
      navigation: {
        nextEl: this.$buttonNext.get(0),
        prevEl: this.$buttonPrev.get(0)
      },
      breakpoints: {
        1024: desktop_breakpoint_config,
        600: {
          slidesPerView: 2,
        },
      },
      on: {
        activeIndexChange(){
          let index_currentSlide = this.activeIndex;
          console.log(this.realIndex);
          let currentSlide = this.slides[index_currentSlide];
          change_tab_item($(currentSlide));
        },
        transitionStart: function(){
          let translate = this.getTranslate();
          let slidesPerView = this.params.slidesPerView == 'auto ' ?this.slidesPerViewDynamic() : this.params.slidesPerView;
          if(this.slides.length<=slidesPerView){
              return;
          }
  
          var y = 0;
          var z = 0;
          var x = 0;
  
          if(this.activeIndex > slidesPerView/2)
          {
              translate = this.activeIndex == this.slides.length -1 ? -this.snapGrid[this.snapGrid.length - 2] : this.translate;
  
              if (this.isHorizontal()) {
                  x = this.params.rtl ? -translate : translate;
              } else {
                  y = translate;
              }
  
              if (this.roundLengths) {
                  x = Math.floor(x);
                  y = Math.floor(y);
              }
          }
          
          if (this.support.transforms3d) { this.$wrapperEl.transform(("translate3d(" + x + "px, " + y + "px, " + z + "px)")); }
          else { this.$wrapperEl.transform(("translate(" + x + "px, " + y + "px)")); }
        }
      },
      
    }

    this.swiper = new Swiper(this.$slider.get(0), config)
    
  }
}

window.customElements.define('flynt-tabs-content', TabsSlider, { extends: 'div' })